
import { InitData } from '@/type/home'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router';

export default defineComponent({

    setup() {
        const router = useRouter()

        const data = reactive(new InitData())
        const onHome = () => {
            router.push('/')
        }

        return { ...toRefs(data), onHome }
    }
})
