import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes: Array<RouteRecordRaw> = [
	{
		path: '/home',
		name: "home",
		redirect: "banner",
		component: HomeView,
		children: [
			{
				path: "/",
				name: "banner",
				component: () => import(/* webpackChunkName: "banner" */ '../views/BannerView.vue')
			},
			{
				path: "/product",
				name: "product",
				component: () => import(/* webpackChunkName: "product" */ '../views/ProductView.vue')
			},
			{
				path: "/show",
				name: "show",
				component: () => import(/* webpackChunkName: "show" */ '../views/ShowView.vue')
			},
			{
				path: "/faction",
				name: "faction",
				component: () => import(/* webpackChunkName: "faction" */ '../views/FactionView.vue')
			},
			{
				path: "/about",
				name: "about",
				component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
			}
		]
	}

]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
