export interface BannerListInt {
    cover: string,
    title: string,
    use: string
}

export interface searchDataInt {
    use: string
}
export class InitData {
    searchData:searchDataInt = {
        use: 'webHome'
    }
    menuList = [
        {
            title: '作品',
            path: '/product'
        },
        {
            title: '项目',
            path: '/show'
        },
        {
            title: '深时派',
            path: '/faction'
        },
        {
            title: '关于',
            path: '/about'
        }
    ]
    bannerList:BannerListInt[] = []
    productList:any = []
    pIndex:any = 1
    showList:any = []
    sIndex:any = 1
}

