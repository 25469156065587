<template>
	<router-view />
</template>

<style lang="scss">
@import "//at.alicdn.com/t/c/font_3659089_x24k9qk3ig.css";

* {
	padding: 0;
	margin: 0;
	font-family: '微软雅黑';
}

html {
	//pc端
	@media screen and (min-width:769px) {
		font-size: 10px;
		
	}
	//移动端
	@media screen and (max-width:768px) {
		font-size: 5px;
		
	}
	// overflow-y: scroll;
}

html,
body,
#app {
	width: 100%;
	height: 100%;
}

body {
	font-size: 10px;
	color: #333;
	background: #fff;
	line-height: 24px;
	overflow-y: scroll;

}

fieldset,
img {
	// width: 100%;
	border: 0;
}

ol,
ul,
li {
	list-style: none;
}

em {
	font-style: normal;
}

input,
button,
select,
textarea {
	outline: none;
}

textarea {
	resize: none;
}

p {
	// text-align: justify;
	text-justify: distribute;
}

a {
	color: #333;
	text-decoration: none;
}

a:hover {
	color: #333;
	text-decoration: none;
}
</style>
